<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNew"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="setores"
          class="p-datatable-sm"
          v-model:selection="selectedSetores"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} setores"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <h5>Gestão de Setores</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column field="sigla" header="Sigla" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Sigla</span>
              {{ slotProps.data.sigla }}
            </template>
          </Column>
          <Column field="nome" header="Nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              {{ slotProps.data.nome }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="openUpdate(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="openDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- CADASTRO E ATUALIZAÇÃO-->
  <Dialog
    v-model:visible="setorDialog"
    :style="{ width: '480px' }"
    :header="titleDialog"
    :modal="true"
    @hide="hideDialogs()"
  >
    <form @submit.prevent="saveSetorOrUpdateSetor(!v$.setor.$invalid)">
      <div class="p-fluid">
        <div class="p-field">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            v-model.trim="v$.setor.nome.$model"
            autofocus
            placeholder="Digite o nome do Setor"
            maxlength="255"
            :class="{ 'p-invalid': submitted && v$.setor.nome.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.setor.nome.$invalid"
            >Nome é obrigatório</small
          >
        </div>
        <div class="p-field">
          <label for="sigla">Sigla</label>
          <InputText
            id="nome"
            v-model.trim="v$.setor.sigla.$model"
            placeholder="Digite a sigla do Setor"
            maxlength="50"
            :class="{ 'p-invalid': submitted && v$.setor.sigla.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.setor.sigla.$invalid"
            >Sigla é obrigatória</small
          >
        </div>
      </div>
      <Button
        label="Salvar"
        icon="pi pi-check"
        class="p-button-raised p-mr-2"
        type="submit"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </form>
    <template #footer> </template>
  </Dialog>
  <!-- CADASTRO E ATUALIZAÇÃO-->

  <!-- DELETAR REGISTRO-->
  <Dialog
    v-model:visible="deleteSetorDialog"
    :style="{ width: '450px' }"
    :modal="true"
    :header="titleDialog"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="setor"
        >Tem certeza de que deseja excluir o registro <b>{{ setor.nome }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteSetor()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!-- DELETAR REGISTRO-->
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { FilterMatchMode } from "primevue/api";

import SetorService from "@/service/Setor/SetorService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      setores: null,
      setorDialog: false,
      deleteSetorDialog: false,
      selectedSetores: null,
      setor: {
        id: null,
        nome: null,
        sigla: null,
      },
      filters: {},
      submitted: false,
      titleDialog: null,
    };
  },
  setorService: null,
  validations() {
    return {
      setor: {
        nome: {
          required,
        },
        sigla: {
          required,
        },
      },
    };
  },
  created() {
    this.initFilters();
    this.setorService = new SetorService();
  },
  mounted() {
    this.findAll();
  },
  methods: {
    findAll() {
      this.setorService.findAll().then((data) => {
        this.setores = data;
      });
    },
    openNew() {
      this.titleDialog = "NOVO SETOR";
      this.submitted = false;
      this.setorDialog = true;
    },
    openUpdate(setor) {
      this.setor = setor;
      this.titleDialog = this.setor.nome.toUpperCase();
      this.setorDialog = true;
    },
    openDelete(setor) {
      this.setor = setor;
      this.titleDialog = this.setor.nome.toUpperCase();
      this.deleteSetorDialog = true;
    },
    hideDialogs() {
      this.submitted = false;
      this.setor = {
        id: null,
        nome: null,
        sigla: null,
      };
      this.selectedSetores = null;
      this.setorDialog = false;
      this.deleteSetorDialog = false;
    },
    saveSetorOrUpdateSetor(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }
      if (this.setor.id == null) {
        this.setorService
          .create(this.setor)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAll();
            this.hideDialogs();
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      } else {
        this.setorService.update(this.setor.id, this.setor).then((data) => {
          this.$msgSuccess(data);
          this.findAll();
          this.hideDialogs();
        });
      }
    },
    deleteSetor() {
      this.setorService
        .deleteById(this.setor.id)
        .then((data) => {
          this.$msgSuccess(data);
          this.findAll();
          this.hideDialogs();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.p-invalid {
  color: red;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>